
function initMap(){
    const map = new google.maps.Map(document.getElementById('map'), {
        center: {
            lat: 33.447151,
            lng: 129.969044
        },
        zoom: 16,
        mapTypeControl: false,
        scrollwheel: false,
        styles: [
            {
                'featureType': 'administrative',
                'elementType': 'all',
                'stylers': [
                    {
                        'saturation': '-100'
                    }
                ]
            },
            {
                'featureType': 'administrative.province',
                'elementType': 'all',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'landscape',
                'elementType': 'all',
                'stylers': [
                    {
                        'saturation': -100
                    },
                    {
                        'lightness': 65
                    },
                    {
                        'visibility': 'on'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'all',
                'stylers': [
                    {
                        'saturation': -100
                    },
                    {
                        'lightness': '0'
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'all',
                'stylers': [
                    {
                        'saturation': '-100'
                    }
                ]
            },
            {
                'featureType': 'road.highway',
                'elementType': 'all',
                'stylers': [
                    {
                        'lightness': '50'
                    }
                ]
            },
            {
                'featureType': 'road.arterial',
                'elementType': 'all',
                'stylers': [
                    {
                        'lightness': '30'
                    }
                ]
            },
            {
                'featureType': 'road.local',
                'elementType': 'all',
                'stylers': [
                    {
                        'lightness': '40'
                    }
                ]
            },
            {
                'featureType': 'transit',
                'elementType': 'all',
                'stylers': [
                    {
                        'saturation': -100
                    },
                    {
                        'visibility': 'simplified'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'geometry',
                'stylers': [
                    {
                        'hue': '#ffff00'
                    },
                    {
                        'lightness': 0
                    },
                    {
                        'saturation': -97
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'labels',
                'stylers': [
                    {
                        'lightness': -25
                    },
                    {
                        'saturation': -100
                    }
                ]
            }
        ]
    });
    const marker = new google.maps.Marker({
        position: new google.maps.LatLng(33.447151, 129.969044),
        map: map
    });

}